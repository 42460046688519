<template>
  <div style="background-color: white">
    <v-card
      v-if="showOffer"
      color="#6AFEC5" flat tile
      style="z-index: 20; position: sticky !important; top: 73px; left: 0; width: 100%; height: fit-content"
      class="d-flex white--text align-center justify-center pa-3">
      <div class="d-flex flex-column align-center justify-center">
        <arrivada-enviament class="text-center black--text"></arrivada-enviament>

        <v-chip class="mt-1" color="white">
        <span class="font-rubik font-weight-medium" style="font-size: 15px">
        <span class="mr-1">📦</span>
       Enviament gratuït a partir de 5 dossiers
      </span>
        </v-chip>

      </div>

      <v-icon
        @click="showOffer=false" color="white" size="18"
        style="position: absolute; right: 10px; top: 6px">{{ mdiClose() }}
      </v-icon>
    </v-card>

    <!--    <v-card style="position: fixed; top: 140px; right: 100px; width: 100%; max-width: 200px;z-index: 30"-->
    <!--            class="pa-4 rounded-xl shadow"-->
    <!--    >-->
    <!--      <arrivada-enviament></arrivada-enviament>-->
    <!--    </v-card>-->


    <div class="mx-auto" style="max-width: 1500px; position: relative; z-index: 1; background-color: white">


      <div :class="$vuetify.breakpoint.smAndDown ? 'my-4 px-2' : 'px-6 my-10'"
           :style="$vuetify.breakpoint.lgAndUp ? 'min-height: 310px' : ($vuetify.breakpoint.md ? 'min-height: 250px' : 'min-height: 160px')"
      >
        <v-carousel
          v-model="carouselModel"
          :show-arrows-on-hover="true"
          height="auto"
          delimiter-icon=""
          hide-delimiters
          continuous cycle
          class="rounded-lg mx-auto"
          flat
          interval="10000"
          style="max-width: fit-content"

        >
          <v-carousel-item
            v-for="(item, idx) in carouselItems"
            class="rounded-lg"
          >
            <v-responsive :aspect-ratio="1159/311">
              <v-img
                style="max-height: 311px"
                :src="item"
                class="rounded-lg"
                contain></v-img>
            </v-responsive>

            <span
              v-if="daysUntilSele() && idx > 0 && !$vuetify.breakpoint.smAndDown"
              style="position: absolute; left: 40px; top: 40px; color: white"
              class="font-weight-medium font-rubik"
            >
              Queden {{ daysUntilSele() }} dies per les PAU
            </span>
          </v-carousel-item>

          <v-card style="position: absolute; right: 20px; bottom: 15px"
                  class="d-flex py-1 rounded-pill"
                  color="#B1B5BF"
                  flat
          >
            <v-card
              v-for="circle in carouselItems.length"
              height="8" width="8"
              :color="carouselModel === circle-1 ? '#0C193A' : 'white'"
              @click="carouselModel=circle-1"
              class="mx-1 rounded-circle"
              flat
            ></v-card>
          </v-card>

        </v-carousel>


<!--        <div class="text-center mt-3">-->
<!--        <span class="grey&#45;&#45;text">-->
<!--      Per a lliuraments en poblacions petites, distants de l'agència de transport, aquesta podria retardar-se 24h-->
<!--      </span>-->
<!--        </div>-->

      </div>


      <!--      <img-->
      <!--        v-if="false"-->
      <!--        class="mx-auto"-->
      <!--        style="max-width: 1500px; width: 100% ;  object-fit: contain; margin-top: -40px"-->
      <!--        :src="backgroundImage()"-->
      <!--      >-->

      <!--      <div class="mt-12 ml-12 white&#45;&#45;text">-->
      <!--        <h1 class="ml-12 white&#45;&#45;text font-weight-bold"-->
      <!--            style="font-family: Inter, sans-serif !important; z-index: 3 !important; font-size: 50px">-->
      <!--          Dossiers de la Selectivitat-->
      <!--        </h1>-->

      <!--        <p class="ml-12" style="z-index: 3 !important;">-->
      <!--          Els dossiers de la Selectivitat del curs 2022-2023 és el material idoni <br>per estudiar per les PAU sense-->
      <!--          perdre el temps.-->
      <!--        </p>-->
      <!--      </div>-->

      <!--            <v-card  class="mx-6 mt-6 shadow-small rounded-lg" color="#fafafa">-->
      <!--              <v-img-->
      <!--                class="offer-image"-->
      <!--                width="100%" height="100%" :src="$vuetify.breakpoint.smAndDown ?offerImageSm() : offerImage()"></v-img>-->
      <!--            </v-card>-->
      <!--        <h1 class="title tit font-weight-bold" style="font-size: 29px !important;">Col·lecció de problemes</h1>-->


      <div v-if="books && books.length" class="pb-6 pt-0 mx-auto"
           :style="$vuetify.breakpoint.xs ? 'padding-top: 150px' : 'padding-top: 330px'"
           style="max-width: 1159px"
           :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : ''"
      >


        <div v-for="(branch, bIdx) in books" style="margin-bottom: 50px">
          <span
            style="font-size: 2rem"
            class="font-rubik font-weight-medium"
          >
<!--            <span v-if="branch.branch_emoji">-->
            <!--            {{getEmoji(branch)}}-->
            <!--              </span>-->
            {{ branch.branch_name }}
          </span>
          <items-row title="Dossiers d'exàmens"
                     :items="branch.subjects"
                     class="mt-3"
          ></items-row>
          <!--          <items-row title="Dossiers d'apunts"-->
          <!--                     :items="branch.apunts"-->
          <!--                     :apunts="true"-->
          <!--                     class="mt-3"-->
          <!--          ></items-row>-->

        </div>

      </div>

      <v-row
        v-else
        v-for="row in 2"
        :key="`row-shop-page-${row}`"
        class="pb-6 pt-0 mx-auto"
        :style="$vuetify.breakpoint.xs ? 'margin-top: 100px' : 'margin-top: 110px'"
        style="max-width: 1159px"
      >
        <v-col
          v-for="book in 4"
          class="mb-12 rounded-lg"
          cols="12" sm="6" md="4"
          lg="3"
          xl="3"
        >
          <v-skeleton-loader type="image" height="150" class="pb-0 ma-0 rounded-b-0"></v-skeleton-loader>
          <v-skeleton-loader type="image, image" height="150" class="pb-0 ma-0 rounded-t-0"></v-skeleton-loader>
          <v-skeleton-loader type="card-heading" class="pb-0 ma-0"></v-skeleton-loader>
        </v-col>

      </v-row>

    </div>


    <dossiers-images
      v-if="books && books.length"
    ></dossiers-images>


    <v-card
      v-if="books && books.length"
      class="py-12" flat color="white">
      <div style="max-width: 1520px" class="mx-auto text-center">
        <reviews></reviews>
      </div>
    </v-card>


    <v-card
      v-if="false"
      style="position: fixed; left: 40px; bottom: 40px; z-index: 10"
            class="pa-4 font-rubik rounded-lg shadow-sm"
    >
      <span>Vols els dossiers amb una estructura diferent?</span>
      <br>
      <span>Escriu-nos a
     <a data-v-2595c7a6=""
        style=" font-family: Rubik, sans-serif !important;"
        href="mailto:contacte@examenselectivitat.cat" target="_top"> contacte@examenselectivitat.cat</a>
      </span>

    </v-card>


  </div>


</template>

<script>

import ItemsRow from "./components/ProductsRow.vue";
// import {Stories} from "vue-insta-stories";
import Reviews from "./components/Reviews";
import DossiersImages from "./components/DossiersImages";
import {mdiClose} from "/src/assets/mdi.json";
import ArrivadaEnviament from "./components/ArrivadaEnviament.vue";

export default {
  name: "ShopPage",
  metaInfo() {
    return {
      title: `Dossiers de la Selectivitat per Temes`,
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.subtitle},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {ArrivadaEnviament, DossiersImages, Reviews, ItemsRow},
  async mounted() {
    try {
      this.loading = true;
      await this.fetchBooks()
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      books: [],
      url: process.env.VUE_APP_AXIOS_URI,
      showOffer: true,
      carouselModel: 0,
      carouselItems: [
        "https://examenselectivitat.cat:3000/api/images/other/shipping-header.webp",
        "https://examenselectivitat.cat:3000/api/images/other/examens-header-_1_.webp",
        // "https://examenselectivitat.cat:3000/api/images/other/apunts-header.webp",
      ]
    }
  },
  methods: {
    getEmoji(branch) {
      return Buffer.from(branch.branch_emoji, 'base64').toString()
    },
    daysUntilSele() {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const dia = 4; // 4
      const mes = 6; // de juny
      const any = new Date().getFullYear() // D'aquest any
      const firstDate = new Date(mes + "/" + dia + "/" + any);
      const secondDate = new Date();

      const res = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return res > 0 ? res : null
    },
    mdiClose() {
      return mdiClose
    },
    // https://examenselectivitat.cat:3000/api/images/other/dossiers-bg.webp
    backgroundImage() {
      if (this.$vuetify.breakpoint.xs)
        return 'https://examenselectivitat.cat:3000/api/images/other/dossiers-bg-xs.png'

      return 'https://examenselectivitat.cat:3000/api/images/other/dossiers-bg.webp'
    },
    async fetchBooks() {
      const {data} = await this.axios.get('/shop-list')

      this.books = data;
    },
    offerImage() {
      return this.url + '/images/other/OfertaBooklets.png'
    },
    offerImageSm() {
      return this.url + '/images/other/OfertaBooklets_sm.png'
    },
    async updatePreviews() {
      await this.axios.get(`/update-dossiers-previews`)
    }
  }
}
</script>

<style scoped>
.scroll-left {
  height: 30px;
  overflow: hidden;
  position: relative;
}

.scroll-left p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  /* Starting position */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: scroll-left 15s linear infinite;
  -webkit-animation: scroll-left 15s linear infinite;
  animation: scroll-left 15s linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%); /* Browser bug fix */
    -webkit-transform: translateX(100%); /* Browser bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Browser bug fix */
    -webkit-transform: translateX(-100%); /* Browser bug fix */
    transform: translateX(-100%);
  }
}


#pdf-viewer >>> canvas {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1) !important;
  margin-bottom: 10px;
  width: 100%;
}


.offer-image >>> .v-image__image {
  image-rendering: optimizeSpeed !important;
  image-rendering: -moz-crisp-edges !important;;
  image-rendering: -webkit-optimize-contrast !important;;
  image-rendering: -o-crisp-edges !important;;
  image-rendering: crisp-edges !important;;
  -ms-interpolation-mode: nearest-neighbor !important;;
}

/** full screen on mobile & fixed size on desktop **/


@media (min-width: 768px) {
  .ig-stories {
    position: relative;
    height: 730px;
    width: 420px;
  }
}


.ig-stories >>> img {
  object-fit: cover !important;
  height: 100%;
  margin: 0 !important;
}

</style>
